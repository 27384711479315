import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import copy from 'copy-to-clipboard';

import SectionHeader from '../components/SectionHeader';
import Switch from '../components/Switch';
import Toggle from '../components/Toggle';
import Tooltip from '../components/Tooltip';

import * as FlashNotificationActions from '../actions/FlashNotifications';
import * as AccountActions from '../actions/Accounts';

import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from 'react-syntax-highlighter/dist/languages/prism/jsx';
import style from 'react-syntax-highlighter/dist/styles/prism/tomorrow'; 
SyntaxHighlighter.registerLanguage('jsx', jsx);

class CopySection extends Component {
  render() {
    const src = '//cdn.zigpoll.com/static/js/main.js';
    let code = 
`<script type='application/javascript'>
  ;(function () {
    window.Zigpoll = {
      accountId: '${this.props.accountId}'
    };

    var script = document.createElement("script");
    script.type = "text/javascript";
    script.charset = "utf-8";
    script.src = "${src}";
    script.async = true;

    document.head.appendChild(script);
  }());
</script>`;

  if (this.props.user.shop && this.props.type === 'shopify') {
    code = 
`<script type="text/javascript" async src="https://cdn.zigpoll.com/zigpoll-shopify-embed.js?accountId=${this.props.accountId}&shop=${this.props.user.shop}"></script>`;
  }

    return (
      <div className={`embed-code card`} onClick={e => {
        copy(code);
        this.props.flash('The code has been copied to your clipboard.');
      }}>
        <SyntaxHighlighter 
          wrapLines={true} 
          lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
          language='javascript'
          style={style}
          customStyle={{padding:25, fontSize: 14, borderRadius: 10, overflow: 'hidden'}}>{code}</SyntaxHighlighter>
      </div>
    );
  }
}

class EmbedCode extends Component {
  constructor(props) {
    super(props);
    this.state = { embedCodeType: 'shopify' };
  }

  componentDidMount() {
    this.props.fetchAccount(undefined, undefined, true);
  }

  render() {
    if (this.props.user.type === 'shopify') {
      let shopifyScriptTag = this.props.account.shopifyScriptTag || { scriptDisplayScope: 'disabled' };
      let scriptDisplayScope = shopifyScriptTag.scriptDisplayScope || 'online_store';
      let autoShopifyEmbed = this.props.account.autoShopifyEmbed === undefined ? true : this.props.account.autoShopifyEmbed;

      if (scriptDisplayScope === 'all' || scriptDisplayScope === 'order_status') {
        scriptDisplayScope = 'online_store';
      }

      return <span>
        <SectionHeader
          title=<span><i class="fas fa-code"></i>Automatic Embed Code</span>
          subtitle="Automatically add the embed code to your Shopify store."
          tooltip="If you disable this, you will need to add the code manually."
          bottom={true}
        />

        <div className="card shopify-embed" style={{ marginBottom: 25, maxWidth: 600 }}>
          <label>Embed Code Settings<Tooltip bottom={true}>By default, we will handle adding and removing the embed code to your Shopify store based off of what surveys you have running. If you would like to disable this feature and handle it yourself; use the options below.</Tooltip></label>
          <div className="inline-toggle" style={{ marginBottom: 25 }}>
            <Toggle
              active={autoShopifyEmbed}
              disabled={this.props.accounts.loading}
              onChange={(value) => {
                let msg;
                if (autoShopifyEmbed) {
                  msg = 'Your embed code is no longer managed by Zigpoll.'
                } else {
                  msg = 'Your embed code is now managed by Zigpoll.'
                }
                this.props.toggleAutoShopifyEmbed(msg);
              }}
            />
            <div className="subtle">Allow Zigpoll to automatically manage the embed code.</div>
          </div>
          <div className="inline-toggle absolute">
            <Switch 
              disabled={this.props.accounts.loading || autoShopifyEmbed}
              vertical={true}
              options={[
                // { label: <span>Add the embed code to <strong>checkout</strong> and <strong>order status</strong> pages</span>, value: 'order_status'},
                { label: <span>Add the embed code to <strong>online store pages</strong></span>, value: 'online_store'},
                { label: <span><strong>Do not</strong> automatically add the embed code</span>, value: 'disabled'}
              ]}
              className="large"
              value={scriptDisplayScope}
              onChange={(value) => {
                let msg;
                if (value === 'disabled') {
                  msg = 'Your automatic embed code has been removed.'
                } else {
                  msg = 'Your embed code has been added to your store.'
                }
                this.props.updateEmbedCode(value, msg);
              }}
            />
          </div>
        </div>

        <div className="card info" style={{ marginBottom: 25, marginTop: -10 }}>
          <p>If you <strong>are</strong> using <a href="https://help.shopify.com/en/manual/checkout-settings/customize-checkout-configurations/checkout-extensibility" target="_blank">checkout or account extensibility</a> → Please <a href="https://www.zigpoll.com/blog/zigpoll-shopify-checkout-extensions" target="_blank">add the app block to your post purchase and customer profile pages</a>.</p><br/>
          <p>If you <strong>are not</strong> using checkout or account extensibility → <a href="https://www.zigpoll.com/blog/zigpoll-legacy-post-purchase-installation" target="_blank">Add the Zigpoll script to your post purchase pages</a>.</p>
        </div>

        <SectionHeader
          title=<span><i className="fas fa-terminal"/>Manual Embed Code</span>
          subtitle="Click to copy the code to your clipboard."
          tooltip={`${this.props.account.shopifyScriptTag ? 'You have the automatic embed code enabled. You do not need to add this script to your shopify storefronts.' : 'All you need to do is place this code in the <head> or <body> section of your website.'}`}
        />

        <div>
          <div style={{ maxWidth: `600px`, marginBottom: '10px' }} className={`medium-switch ${this.state.embedCodeType === 'shopify' ? 'left' : 'right'}`}>
            <div 
              onClick={() => this.setState({ embedCodeType: 'shopify' })}
              className={`${this.state.embedCodeType === 'shopify' ? 'active' : ''}`}>Shopify Embed Code</div>
            <div 
              onClick={() => this.setState({ embedCodeType: 'manual' })}
              className={`${this.state.embedCodeType === 'manual' ? 'active' : ''}`}>Regular Embed Code</div>
          </div>
        </div>

        <CopySection user={this.props.user} type={this.state.embedCodeType} accountId={this.props.account._id} flash={this.props.flash} />

      </span>
    }

    return (
      <span>
        <SectionHeader
          title=<span><i className="fas fa-code" />Embed Code</span>
          subtitle="Click to copy the code to your clipboard."
          tooltip="All you need to do is place this code in the <head> or <body> section of your website."
          bottom={this.props.bottom}
        />
        <CopySection user={this.props.user} accountId={this.props.account._id} flash={this.props.flash} />
        <div className="card info" style={{ marginTop: 0, maxWidth: 600 }}>
          <p>{`When including the code on your site make sure to place it in the <head> or <body> sections of your website so that it can be loaded properly.`}</p>
        </div>
      </span>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...FlashNotificationActions, ...AccountActions }, dispatch);
}

export default connect(null, mapDispatchToProps)(EmbedCode);
