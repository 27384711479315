import React, { Component } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
import HeatMapModule from 'highcharts/modules/heatmap';
import ExportingModule from "highcharts/modules/exporting";
import ExportDataModule from "highcharts/modules/export-data";

import _ from 'lodash';

import { numberWithCommas, renderNumber } from '../utils';

HeatMapModule(Highcharts);
ExportingModule(Highcharts);
ExportDataModule(Highcharts);

export class PieChart extends Component {
  constructor(props) {
    super(props);

    let tooltipContent = this.props.tooltipContent || function () {
      return `<div><b>Responses</b>: ${numberWithCommas(this.point.y)} <span>${parseFloat(this.point.percentage).toFixed(1)}%</span></div>`
    }

    let labelFormatter = function () { return `<strong>${this.point.name}</strong>: ${numberWithCommas(this.point.y)} Votes (${parseFloat(this.point.percentage).toFixed(1)}%)` };

    const options = {
      title: {},
      chart: {
        type: 'pie',
        styledMode: true,
        height: 300
      },
      showTable: false,
      tooltip: {
        useHTML: true,
        borderRadius: 10,
        formatter: function (val) {
          return `<div class="tooltip-wrapper">
            <div class="tooltip-header-large">${this.point.name}</div>
            <div class="tooltip-content">
              ${tooltipContent.call(this)}
            </div>
          </div>`;
        }
      },
      plotOptions: {
        series: { animation: false },
        pie: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
              enabled: true,
              formatter: this.props.labelFormatter || labelFormatter
          }
        }
      },
      series: [{
        name: 'Votes',
        colorByPoint: true,
        data: this.props.data,
      }]
    };

    this.state = { options };
  }

  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      const state = { ...this.state };
      const series = [ ...state.options.series ];
      series[0].data = nextProps.data;
      state.series = series;
      this.setState(state);
      return true;
    }
    return false;
  }
 
  render() {
    let title = null;
    if (this.props.title) {
      title = <div className="title">{ this.props.title }</div>
    }
    return <div className="highcharts-wrapper">
      { title }
      <HighchartsReact
        highcharts={Highcharts}
        options={this.state.options}
        updateArgs={[true, false, false]}
      />
    </div>
  }
}

export class DonutChart extends Component {
  constructor(props) {
    super(props);

    let tooltipContent = this.props.tooltipContent || function () {
      return `<div><b>Responses</b>: ${numberWithCommas(this.point.y)} <span>${parseFloat(this.point.percentage).toFixed(1)}%</span></div>`
    }

    let labelFormatter = function () { return `<strong>${this.point.name}</strong>: ${numberWithCommas(this.point.y)} Votes (${parseFloat(this.point.percentage).toFixed(1)}%)` };

    const options = {
      title: {},
      chart: {
        type: 'pie',
        styledMode: true,
        height: 300
      },
      tooltip: {
        useHTML: true,
        borderRadius: 10,
        formatter: function (val) {
          return `<div class="tooltip-wrapper">
            <div class="tooltip-header-large">${this.point.name}</div>
            <div class="tooltip-content">
              ${tooltipContent.call(this)}
            </div>
          </div>`;
        }
      },
      plotOptions: {
        series: { animation: false },
        pie: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
              enabled: true,
              formatter: this.props.labelFormatter || labelFormatter
          }
        }
      },
      series: [{
        name: 'Votes',
        colorByPoint: true,
        data: this.props.data,
        startAngle: -90,
        endAngle: 90,
        dataLabels: {
          distance: 10
        },
        center: ['50%', '100%'],
        size: '197%',
        innerSize: '70%',
      }]
    };

    this.state = { options };
  }

  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      const state = { ...this.state };
      const series = [ ...state.options.series ];
      series[0].data = nextProps.data;
      state.series = series;
      this.setState(state);
      return true;
    }
    return false;
  }
 
  render() {
    return <div className="highcharts-wrapper">
      <HighchartsReact
        highcharts={Highcharts}
        options={this.state.options}
        updateArgs={[true, false, false]}
      />
    </div>
  }
}

export class ColumnChart extends Component {
  constructor(props) {
    super(props);

    let tooltipContent = this.props.tooltipContent || function () {
      return `<div><b>Responses</b>: ${numberWithCommas(this.point.y)} <span>${parseFloat(this.point.perc * 100).toFixed(1)}%</span></div>`
    }

    let labelFormatter = function () { return `<strong>${this.point.name}</strong>: ${numberWithCommas(this.point.y)} Votes (${parseFloat(this.point.perc * 100).toFixed(1)}%)` };

    const options = {
      title: {},
      chart: {
        type: 'column',
        styledMode: true,
        height: 300
      },
      tooltip: {
        useHTML: true,
        borderRadius: 10,
        formatter: function (val) {
          return `<div class="tooltip-wrapper">
            <div class="tooltip-header-large">${this.point.name}</div>
            <div class="tooltip-content">
              ${tooltipContent.call(this)}
            </div>
          </div>`;
        }
      },
      legend: { enabled: false },
      yAxis: {
        title: { enabled: false }
      },
      xAxis: {
        categories: this.props.categories,
        crosshair: true,
      },
      plotOptions: {
        series: { animation: false },
        pie: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
              enabled: true,
              formatter: this.props.labelFormatter || labelFormatter
          }
        }
      },
      series: [{
        data: this.props.data,
        colorByPoint: true,
      }]
    };

    this.state = { options };
  }

  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      const state = { ...this.state };
      const series = [ ...state.options.series ];
      series[0].data = nextProps.data;
      state.series = series;
      this.setState(state);
      return true;
    }
    return false;
  }
 
  render() {
    let title = null;
    if (this.props.title) {
      title = <div className="title">{ this.props.title }</div>
    }

    return <div className="highcharts-wrapper">
      { title }
      <HighchartsReact
        highcharts={Highcharts}
        options={this.state.options}
        updateArgs={[true, false, false]}
      />
    </div>
  }
}

export class NPSChart extends Component {
  constructor(props) {
    super(props);

    let defaultTooltipContent;
    let tooltipContent = defaultTooltipContent = function () {
      return `<div><b>Responses</b>: ${numberWithCommas(this.point.votes)} <span>${parseFloat(this.point.percentage).toFixed(1)}%</span></div>`
    }

    let labelFormatter = function () { return `<strong>${this.point.name}</strong>: ${numberWithCommas(this.point.votes)} Votes (${parseFloat(this.point.percentage).toFixed(1)}%)` };

    this.state = {};

    const series = this.prepareSeries();
    const options = {
      title: {},
      chart: {
        type: 'pie',
        styledMode: true,
        height: 300,
      },
      tooltip: {
        useHTML: true,
        borderRadius: 10,
        formatter: function (val) {
          if (this.series.userOptions.className === 'nps-groupings') {
            return `<div class="tooltip-wrapper">
              <div class="tooltip-header-large">${this.point.name}</div>
              <div class="tooltip-content">
                ${defaultTooltipContent.call(this)}
              </div>
            </div>`;
          }
          return `<div class="tooltip-wrapper">
            <div class="tooltip-header-large">${this.point.name}</div>
            <div class="tooltip-content">
              ${tooltipContent.call(this)}
            </div>
          </div>`;
        }
      },
      legend: { enabled:false },
      plotOptions: {
        series: { 
          animation: false,
        },
      },
      yAxis: {
        min: 0,
        title: 'Revenue',
        labels: {
          formatter: function () {
            return renderNumber(this.value)
          }
        }
      },
      xAxis: {
        categories: props.categories,
        crosshair: true
      },
      series
    };

    this.state.options = options;
  }

  prepareGroupings(data) {
    data = data || this.props.data;
    // data.reverse();

    const len = data.length;
    const groups = [{
        name: 'Detractors',
        y: 0,
      }, {
        name: 'Passives',
        y: 0,
      }, {
        name: 'Promoters',
        y: 0,
      }
    ];

    let total = 0;
    let promotersTotal = 0;
    let detractorsTotal = 0;

    let breakpoints = { detractor: 0.7, promoter: 0.9 };

    if (len <= 5) {
      breakpoints = { detractor: 0.5, promoter: 0.8 };
    }

    let groupIdx = 0;
    let prevGroup = '';
    
    data.forEach((val, idx) => {
      let className = '';
      const perc = (idx+1)/len;

      total += val.y || 0;

      if (perc < breakpoints.detractor) {
        if (prevGroup !== 'detractor') {
          groupIdx = 0;
          prevGroup = 'detractor';
        }
        className = 'detractor idx-'+groupIdx++;

        groups[0].y += val.y || 0;
        detractorsTotal += val.y || 0;
      } else if (perc < breakpoints.promoter) {
        if (prevGroup !== 'passive') {
          groupIdx = 0;
          prevGroup = 'passive';
        }
        className = 'passive idx-'+groupIdx++;

        groups[1].y += val.y || 0;
      } else {
        if (prevGroup !== 'promoter') {
          groupIdx = 0;
          prevGroup = 'promoter';
        }
        className = 'promoter idx-'+groupIdx++;

        groups[2].y += val.y || 0;      
        promotersTotal += val.y || 0;
      }

      val.className = className;
    });

    data = _.remove(data, ({ y }) => !y);

    const npsScore = ((promotersTotal / total) - (detractorsTotal / total)) * 100;

    return { groups, npsScore };
  }

  prepareSeries() {
    const { groups, npsScore } = this.prepareGroupings();
    this.state.npsScore = npsScore;

    const series = [{
        colorByPoint: true,
        data: this.props.data,
        startAngle: -90,
        endAngle: 90,
        dataLabels: {
          distance: 10
        },
        className: 'nps-results',
        center: ['50%', '100%'],
        size: '167%',
        innerSize: '65%',
      }, {
        colorByPoint: true,
        className: 'nps-groupings',
        data: groups,
        dataLabels: {
          enabled: false
          // distance: -45,
        },
        startAngle: -90,
        endAngle: 90,
        center: ['50%', '100%'],
        size: '100%',
        innerSize: '85%',
      }]
    return series;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      const { groups, npsScore } = this.prepareGroupings(nextProps.data)
      const state = { ...this.state };
      const series = [ ...state.options.series ];
      series[0].data = nextProps.data;
      series[1].data = groups;
      state.series = series;
      state.npsScore = npsScore;

      this.setState(state, () => this.forceUpdate());
      return true;
    }
    return false;
  }
 
  render() {
    let title = null;
    if (this.props.title) {
      title = <div className="title">{ this.props.title }</div>
    }
    return <div className="nps-results-chart">
      <div className="highcharts-wrapper">
        { title }
        <div className="nps-score"><span>NPS</span>{ (this.state.npsScore === undefined) ? '--' : Math.round(this.state.npsScore) }</div>
        <HighchartsReact
          highcharts={Highcharts}
          options={this.state.options}
          updateArgs={[true, false, false]}
        />
      </div>
    </div>
  }
}


export class HeatmapChart extends Component {
  constructor(props) {
    super(props);

    let tooltipContent = this.props.tooltipContent || function (self) {
      return `
        <div style="margin-bottom: 5px">Votes: <span>${this.point.value}</span></div>
        <div class="small">Row Total: ${renderNumber(this.point.rowTotal)} <em>(${renderNumber(this.point.value)}/${renderNumber(this.point.rowTotal)} = ${renderNumber((this.point.value/this.point.rowTotal) * 100)}%)</em></div>
        <div class="small">Column Total: ${renderNumber(this.point.columnTotal)} <em>(${renderNumber(this.point.value)}/${renderNumber(this.point.columnTotal)} = ${renderNumber((this.point.value/this.point.columnTotal) * 100)}%)</em></div>
        <div class="small">Table Total: ${renderNumber(self.props.total)} <em>(${renderNumber(this.point.value)}/${renderNumber(self.props.total)} = ${renderNumber((this.point.value/self.props.total) * 100)}%)</em></div>`
    }
    let self = this;

    const options = {
      title: {
        text: null
      },
      chart: {
        type: 'heatmap',
        styledMode: true,
        height: 500
      },
      xAxis: {
          categories: this.props.xAxisCategories,
          title: null,
          opposite: true
      },
      yAxis: {
          categories: this.props.yAxisCategories,
          title: null,
          reversed: true,
      },
      colorAxis: {
          min: 0,
          minColor: '#f5f7f9',
          maxColor: '#2167f5'
      },
      tooltip: {
        useHTML: true,
        borderRadius: 10,
        formatter: function (val) {
          console.log(this);
          console.log(val);
          return `<div class="tooltip-wrapper">
            <div class="tooltip-header-large">${this.series.yAxis.categories[this.point.y]} → ${this.series.xAxis.categories[this.point.x]}</div>
            <div class="tooltip-content">
              ${tooltipContent.call(this, self)}
            </div>
          </div>`;
        }
      },
      legend: {
          align: 'right',
          layout: 'vertical',
          margin: 0,
          verticalAlign: 'top',
          y: 25,
          symbolHeight: 280
      },
      series: [{
        name: 'data',
        borderWidth: 1,
        data: this.props.data,
        dataLabels: {
          enabled: true,
          color: '#000000'
        },
      },
      {
        name: 'totals',
        borderWidth: 1,
        data: this.props.totals,
        enableMouseTracking: false,
        dataLabels: {
          enabled: true,
          color: '#000000',
          format: '{point.va}'
        },
      }],
    };

    this.state = { options };
    console.log(this.state);
  }

  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      const state = { ...this.state };
      const series = [ ...state.options.series ];
      series[0].data = nextProps.data;
      series[1].data = nextProps.totals;
      state.series = series;
      state.options.xAxis.categories = nextProps.xAxisCategories;
      state.options.yAxis.categories = nextProps.yAxisCategories;
      this.setState(state);
      return true;
    }
    if (nextProps.loading !== this.props.loading) {
      return true;
    }
    return false;
  }
 
  render() {
    let title = null;
    if (this.props.loading) {
      return <div className="loading"></div>      
    }
    if (this.props.title) {
      title = <div className="title">{ this.props.title }</div>
    }
    if (this.props.data.length === 0) {
      return <div className="not-enough-data"><i className="fas fa-info-circle" />Not enough data yet. Please try again later.</div>
    }
    return <div id="heatmap" className="highcharts-wrapper">
      { title }
      <HighchartsReact
        ref="chart"
        highcharts={Highcharts}
        options={this.state.options}
        updateArgs={[true, false, false]}
      />
    </div>
  }
}